<template>
  <div>
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading"><div class="breadcrumbs"><router-link to="/admin/accounting" class="no-link">Accounting</router-link> &bull; <router-link to="/admin/accounting" class="no-link">Chart of Accounts</router-link></div> {{ account.name }}</h5>
            </div>
            <div class="page-title-right">
              <button type="button" class="btn btn-primary btn-rounded mr-2" @click="editLedgerEntry()"><i class="fal fa-pencil mr-2"></i>New Entry</button>
            </div>
          </div>

          <div class="widget-list">

            <div class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <div class="row">
                    <div class="col">
                      <router-link :to="`/admin/accounting/ledger/${$route.params.accountid}/${Number(year) - 1}`"><i class="fal fa-chevron-left fa-fw mr-2"></i></router-link>
                      <h6 class="d-inline-block fw-400 mt-2">{{ year }}</h6>
                      <router-link :to="`/admin/accounting/ledger/${$route.params.accountid}/${Number(year) + 1}`"><i class="fal fa-chevron-right fa-fw ml-2"></i></router-link>
                    </div>
                    <div class="col">
                      <h6 class="fw-400 text-right mt-2 mb-2 pr-2">Total: {{ account.ending_balance - account.beginning_balance | formatAmount(true) }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-for="(m, index) in months" :key="m">
              <div class="widget-holder mb-0">
                <div class="widget-bg">
                  <div class="widget-body">
                    <h6 class="fw-400 text-right mt-2 mb-2 pr-2">Monthly Total: {{ endingBalance(m) - beginningBalance(m) | formatAmount(true) }}</h6>
                    <h6 class="fw-400 text-right mt-2 mb-4 pr-2">Ending Balance: {{ endingBalance(m) | formatAmount(true) }}</h6>
                    <table class="table">
                      <thead>
                        <tr><th class="w-15">Date</th><th class="w-40">Description</th><th class="w-15 text-right">Debit (+)</th><th class="w-15 text-right">Credit (-)</th><th class="w-15 text-right">Balance</th></tr>
                      </thead>
                      <tbody>
                        <tr v-for="e in txMonthGroup(m)" :key="e.id">
                          <td>{{ $datefns.utc.format(e.tx_at, 'MMM d') }}</td>
                          <td><a class="no-link" title="Edit" @click="editLedgerEntry(e.id)"><div class="cursor-pointer w-100">{{ e.description }}&nbsp;</div></a></td>
                          <td class="text-right" :class="e.debit || 'opacity-0'">{{ e.debit | formatAmount(false) }}</td>
                          <td class="text-right" :class="e.credit || 'opacity-0'">{{ e.credit | formatAmount(false) }}</td>
                          <td class="text-right">{{ e.balance | formatAmount(false) }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <h6 class="fw-400 text-right mb-3 mt-4 pr-2">Beginning Balance: {{ beginningBalance(m) | formatAmount(true) }}</h6>
                  </div>
                </div>
              </div>
              <div v-if="index < months.length - 1">
                <div class="issuedetails-connect"></div>
                <div v-if="$datefns.formatDistance(new Date(months[index + 1]), new Date(m)).includes('year') || ($datefns.formatDistance(new Date(months[index + 1]), new Date(m)).includes('month') && $datefns.formatDistance(new Date(months[index + 1]), new Date(m)) != '1 month')">
                  <div class="clearfix issuedetails-action">
                    <div class="float-left issuedetails-action-icon"><i class="fal fa-clock fa-fw text-muted rounded-circle"></i></div>
                    <div class="float-right issuedetails-action-description text-muted pt-2 mt-1">No transactions for {{ $datefns.formatDistance(new Date(months[index + 1]), new Date(m)) }}</div>
                  </div>
                  <div class="issuedetails-connect"></div>
                </div>
              </div>
            </div>
            <div v-if="account.ledger.length === 0" class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <div class="text-center py-4">
                    <h4 class="my-0"><p><i class="fal fa-file-invoice-dollar fa-5x text-stroke-5 text-icon-gray"></i></p><p>No transaction history found.</p></h4>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <b-modal id="ledgerentrymodal" :title="ledgerform.id === '' ? 'New Ledger Entry' : 'Edit Ledger Entry'" size="xl">
            <div class="form-group col-12 col-md-8 col-lg-5 col-xl-4">
              <label class="col-form-label">Date</label>
              <b-form-datepicker v-model="ledgerform.tx_at" locale="en-US" hide-header no-highlight-today value-as-date reset-button reset-button-variant="outline-primary" label-reset-button="Clear" label-help="" menu-class="dropdown-card w-dropdown-card-medium mt-2"></b-form-datepicker>
            </div>
            <table class="table mt-5">
              <thead>
                <tr><th class="w-25">Account</th><th class="w-35">Description</th><th class="w-15 text-right">Debit (+)</th><th class="w-15 text-right">Credit (-)</th><th class="w-10"></th></tr>
              </thead>
              <tbody>
                <tr v-for="(tx, i) in ledgerform.tx" :key="`tx${i}`">
                  <td v-if="ledgerform.id === '' || !showInactive">
                    <select v-model="tx.account" class="form-control custom-select">
                      <option></option>
                      <option v-for="a in activeAccounts" :key="a.id" :value="a.id">{{ a.name }}</option>
                    </select>
                  </td>
                  <td v-else>
                    <select v-model="tx.account" class="form-control custom-select">
                      <option></option>
                      <option v-for="a in accounts" :key="a.id" :value="a.id">{{ a.name }}</option>
                    </select>
                  </td>
                  <td><input type="text" class="form-control" v-model="tx.description" maxlength="128" @focus="i > 0 && !tx.description && $set(tx, 'description', ledgerform.tx[i - 1].description)" @keyup.enter="saveLedgerEntry()"></td>
                  <td><input type="text" class="form-control text-right" v-model="tx.debit" maxlength="9" @keydown="setAmount(tx, 'debit', $event)" @keyup.enter="saveLedgerEntry()"></td>
                  <td><input type="text" class="form-control text-right" v-model="tx.credit" maxlength="9" @keydown="setAmount(tx, 'credit', $event)" @keyup.enter="saveLedgerEntry()"></td>
                  <td class="text-right">
                    <button type="button" class="btn btn-link py-2 px-1" title="Add" @click="ledgerform.tx.splice(i+1, 0, {})" tabindex="-1"><i class="fal fa-plus fa-fw text-primary"></i></button>
                    <button type="button" class="btn btn-link py-2 px-1" title="Delete" @click="ledgerform.tx.splice(i, 1)" :disabled="ledgerform.tx.length === 1" tabindex="-1"><i class="fal fa-minus fa-fw text-primary" :class="ledgerform.tx.length === 1 && 'text-muted opacity-05'"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="ledgerform.files.length > 0" class="mt-4">
              <i class="fal fa-paperclip text-primary mr-2"></i>
              <span v-for="(file, i) in ledgerform.files" :key="`file${i}`" class="badge mr-2">
                <span v-if="file.id" class="scope bg-light font-weight-normal"><a :href="`${$api.defaults.baseURL}/accounting/ledger/${ledgerform.id}/${file.id}/${file.filename}`">{{ file.filename }}</a></span>
                <span v-else class="scope bg-light font-weight-normal">{{ file.filename }}</span>
                <span class="name bg-light font-weight-normal"><a class="btn-link cursor-pointer" title="Delete" @click="ledgerform.files.splice(i, 1)"><i class="fal fa-times text-primary"></i></a></span>
              </span>
            </div>
            <template v-slot:modal-footer>
              <button type="button" class="btn btn-primary btn-rounded mr-2" @click="saveLedgerEntry()">Save Entry</button>
              <button v-if="ledgerform.id !== ''" type="button" class="btn btn-default btn-rounded mr-2" v-b-modal.deleteledgerentrymodal>Delete</button>
              <div class="mr-auto"></div>
              <label class="btn btn-link py-1 px-0 m-0 text-primary"><i class="fal fa-paperclip mr-1"></i>Attach a file<input type="file" class="d-none" @change="uploadFiles($event.target.files)" accept="*" multiple></label>
            </template>
          </b-modal>
          <b-modal id="deleteledgerentrymodal" title="Delete Ledger Entry?">
            This entry will be permanently deleted
            <template v-slot:modal-footer="{ cancel }">
              <button type="button" class="btn btn-danger btn-rounded mr-2" @click="deleteLedgerEntry()">Delete</button>
              <button type="button" class="btn btn-default btn-rounded mr-auto" @click="cancel()">Cancel</button>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin, FormDatepickerPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);
Vue.use(FormDatepickerPlugin);

export default {
  data() {
    return {
      year: this.$route.params.year ? this.$route.params.year : new Date().getFullYear(),
      showInactive: false,
      ledger: [],
      account: {
        ledger: [],
      },
      accounts: [],
      ledgerform: {
        id: '',
        tx_at: new Date(),
        tx: [
          {},
        ],
        files: [],
      },
    };
  },
  computed: {
    activeAccounts() {
      if (this.showInactive) {
        return this.accounts;
      }
      const accounts = [];
      for (let i = 0; i < this.accounts.length; i += 1) {
        if (this.accounts[i].active) {
          accounts.push(this.accounts[i]);
        }
      }
      return accounts;
    },
    months() {
      const m = [];
      for (let i = 0; i < this.account.ledger.length; i += 1) {
        m.unshift(this.account.ledger[i].tx_at.substring(0, 7));
      }
      return Array.from(new Set(m));
    },
  },
  filters: {
    formatAmount(v, includeDollarSign) {
      const amt = (Math.abs(v) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return `${v < 0 ? '(' : ''}${includeDollarSign ? '$' : ''}${amt}${v < 0 ? ')' : ''}`;
    },
  },
  watch: {
    ledgerform: {
      handler() {
        const last = this.ledgerform.tx[this.ledgerform.tx.length - 1];
        // auto set debit/credit balance
        if ((last.account || last.description) && !last.debit && !last.credit) {
          let balance = 0;
          for (let i = 0; i < this.ledgerform.tx.length; i += 1) {
            if (this.ledgerform.tx[i].amount) {
              balance += this.ledgerform.tx[i].amount;
            }
          }
          balance *= -1; // flip balance to offset current balance
          last.amount = balance;
          if (balance > 0) {
            this.$set(last, 'debit', this.$options.filters.formatAmount(balance, false));
          } else if (balance < 0) {
            this.$set(last, 'credit', this.$options.filters.formatAmount(balance * -1, false));
          }
          last.amount_auto_set = true;
        }
        // auto add trailing row
        if (last.account || last.description || last.debit || last.credit) {
          this.ledgerform.tx.push({});
        }
      },
      deep: true,
    },
  },
  methods: {
    beginningBalance(m) {
      for (let i = this.ledger.length - 1; i >= 0; i -= 1) {
        if (this.ledger[i].tx_at.startsWith(m)) {
          return this.ledger[i].balance - this.ledger[i].amount;
        }
      }
      return 0;
    },
    endingBalance(m) {
      for (let i = 0; i < this.ledger.length; i += 1) {
        if (this.ledger[i].tx_at.startsWith(m)) {
          return this.ledger[i].balance;
        }
      }
      return 0;
    },
    txMonthGroup(m) {
      const tx = [];
      for (let i = 0; i < this.ledger.length; i += 1) {
        if (this.ledger[i].tx_at.startsWith(m)) {
          tx.push(this.ledger[i]);
        }
      }
      return tx;
    },
    getAccount() {
      this.$api.get(`/accounting/accounts/${this.$route.params.accountid}/${this.year}`)
        .then((res) => {
          this.account = res.data;
          // set amounts
          this.ledger = [];
          let balance = this.account.beginning_balance;
          for (let i = 0; i < this.account.ledger.length; i += 1) {
            for (let k = 0; k < this.account.ledger[i].tx.length; k += 1) {
              const e = this.account.ledger[i].tx[k];
              e.id = this.account.ledger[i].id;
              e.tx_at = this.account.ledger[i].tx_at;
              if (e.amount > 0) {
                e.debit = e.amount;
              } else {
                e.credit = e.amount;
              }
              balance += e.amount;
              e.balance = balance;
              this.ledger.unshift(e);
            }
          }
          // set page title
          document.title = `${this.account.name} - Accounting - Admin - ${this.$config.pretty_name}`;
        })
        .catch((e) => {
          this.$store.commit('error', e);
        });
    },
    getAccounts() {
      this.$api.get(`/accounting/accounts/all/${this.year}`)
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    setAmount(tx, field, e) {
      if (e.key === 'Tab' || e.key === 'Enter') {
        return; // allow tab
      }
      if (tx.amount === undefined || (tx.amount < 0 && field !== 'credit') || (tx.amount > 0 && field !== 'debit') || tx.amount_auto_set) {
        this.$set(tx, 'amount', 0);
        this.$delete(tx, 'amount_auto_set');
      }
      if (tx.amount < 0) {
        this.$set(tx, 'amount', tx.amount * -1);
      }
      if (e.key >= '0' && e.key <= '9') {
        this.$set(tx, 'amount', (tx.amount * 10) + Number(e.key));
      } else if (e.key === 'Backspace' || e.key === 'Delete') {
        this.$set(tx, 'amount', Math.floor(tx.amount / 10));
      }
      if (tx.amount !== 0) {
        this.$set(tx, field, this.$options.filters.formatAmount(tx.amount, false));
      } else {
        this.$set(tx, field, undefined);
      }
      if (field === 'credit') {
        this.$set(tx, 'debit', undefined);
        this.$set(tx, 'amount', tx.amount * -1);
      } else {
        this.$set(tx, 'credit', undefined);
      }
      e.preventDefault();
    },
    editLedgerEntry(id) {
      if (!id) {
        this.ledgerform.id = '';
        this.ledgerform.tx = [{}];
        this.ledgerform.files = [];
        this.$bvModal.show('ledgerentrymodal');
        return;
      }
      this.$api.get(`/accounting/ledger/${id}`)
        .then((res) => {
          const { tx } = res.data;
          this.$set(this.ledgerform, 'id', res.data.id);
          this.$set(this.ledgerform, 'tx_at', res.data.tx_at);
          for (let i = 0; i < tx.length; i += 1) {
            if (tx[i].amount > 0) {
              tx[i].debit = this.$options.filters.formatAmount(tx[i].amount, false);
            } else {
              tx[i].credit = this.$options.filters.formatAmount(tx[i].amount * -1, false);
            }
            // determine if we should show inactive accounts when editing a ledger entry
            this.showInactive = false;
            if (!this.showInactive) {
              if (!this.account.active) {
                this.showInactive = true;
              } else {
                for (let k = 0; k < this.accounts.length; k += 1) {
                  if (this.accounts[k].id === res.data.tx[i].account) {
                    if (!this.accounts[k].active) {
                      this.showInactive = true;
                    }
                    break;
                  }
                }
              }
            }
          }
          this.$set(this.ledgerform, 'tx', tx);
          if (res.data.files) {
            this.$set(this.ledgerform, 'files', res.data.files);
          }
          this.$bvModal.show('ledgerentrymodal');
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    saveLedgerEntry() {
      this.ledgerform.tx.splice(this.ledgerform.tx.length - 1, 1); // delete empty row
      let request;
      if (this.ledgerform.id === '') {
        request = this.$api.post('/accounting/ledger', this.ledgerform);
      } else {
        request = this.$api.put(`/accounting/ledger/${this.ledgerform.id}`, this.ledgerform);
      }
      request
        .then(() => {
          this.ledgerform.id = '';
          this.ledgerform.tx = [{}];
          this.ledgerform.files = [];
          this.getAccount();
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    uploadFiles(files) {
      for (let i = 0; i < files.length; i += 1) {
        const f = files[i];
        if (f.size > 10 * 1024 * 1024) {
          toast.danger(this, `${f.name} is too large. Maximum file size is 10 MB.`);
        } else {
          // queue file upload
          const fr = new FileReader();
          fr.onload = ((file) => (e) => {
            this.ledgerform.files.push({ filename: file.name, mimetype: file.type, data: (e.target.result.split(','))[1] });
          })(f);
          fr.readAsDataURL(f);
        }
      }
    },
    deleteLedgerEntry() {
      this.$api.delete(`/accounting/ledger/${this.ledgerform.id}`)
        .then(() => {
          this.$bvModal.hide('deleteledgerentrymodal');
          this.ledgerform.id = '';
          this.ledgerform.tx = [{}];
          this.ledgerform.files = [];
          this.getAccount();
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  async mounted() {
    try {
      if (!this.$auth.profile.site_admin) {
        this.$store.commit('error', 404);
        return;
      }
      document.title = `Accounting - Admin - ${this.$config.pretty_name}`;
      this.getAccount();
      this.getAccounts();
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
  },
};
</script>
